<template>
  <div class="extension_wrap">
    <div class="nav_wrap">
      <div class="nav_bar web-center">
        <div class="nav_bar_left">
          <img src="@/assets/img/logo_kyt.png" alt="" class="logo" />
          <ul class="nav">
            <li
              class="nav_item"
              :class="{ active: current == 'extension' }"
              @click="toggle('/extension')"
            >
              插件中心
            </li>
            <li
              class="nav_item"
              :class="{ active: current == 'crossNav' }"
              @click="toggle('/extension/crossNavigation')"
            >
              跨境导航
            </li>
          </ul>
        </div>
        <div class="nav_bar_right">
          <div class="search">
            <input
              type="text"
              v-model.trim="keyword"
              @keyup.enter="search"
              @focus="changeIptColor"
              @blur="removeIptColor"
              placeholder="请输入关键词"
            />
            <img
              @click="search"
              src="@/assets/img/extension/search.png"
              alt=""
              class="search_img"
            />
          </div>
          <div v-if="!islogin" class="login_btn">登录</div>
          <div v-else class="portrait" @click="showLoginout">
            <div class="user_name">{{ real_name }}</div>
            <img
              src="@/assets/img/extension/down_arr.png"
              alt=""
              class="down_arr_img"
            />
            <!-- 退出登录弹窗 -->
            <ul class="popup" v-show="isshowLoginout">
              <li
                class="popup_item"
                @click="$router.push('/extension/extensionManage')"
              >
                <img src="" alt="" class="icon extension_manage_icon" />插件管理
              </li>
              <li class="popup_item" @click="go_out">
                <img src="" alt="" class="icon loginout_icon" />退出登录
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <!-- 右侧添加插件和返回顶部 -->
    <Sidebar></Sidebar>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import { user_logout } from "@/api/login";
export default {
  components: { Sidebar },
  data() {
    return {
      islogin: false,
      real_name: "",
      isshowLoginout: false,
      keyword: "",
      current: "extension",
    };
  },
  created() {
    this.islogin = !!localStorage.token;
    this.real_name =
      localStorage.member && JSON.parse(localStorage.member).real_name;
    this.current = sessionStorage.currentNav || "extension";
  },
  // updated() {
  //   this.current = sessionStorage.currentNav || "extension";
  // },
  watch: {
    $route(to, from) {
      const pathArr1 = [
        "/extension/home",
        "/extension/category",
        "/extension/search",
        "/extension/leaderboard",
        "/extension/detail",
      ];
      const pathArr2 = ["/extension/crossNavigation"];
      const path = to.path;
      if (pathArr1.includes(path)) {
        this.current = "extension";
        sessionStorage.setItem("currentNav", "extension");
      }
      if (pathArr2.includes(path)) {
        this.current = "crossNav";
        sessionStorage.setItem("currentNav", "crossNav");
      }
    },
  },
  methods: {
    toggle(path) {
      // if (path == "/extension") {
      //   this.current = "extension";
      //   sessionStorage.setItem("currentNav", "extension");
      // } else {
      //   this.current = "crossNav";
      //   sessionStorage.setItem("currentNav", "crossNav");
      // }
      this.$router.push(path);
    },
    search() {
      this.$router.push({
        path: "/extension/search",
        query: { keyword: this.keyword },
      });
    },
    changeIptColor(e) {
      e.target.parentNode.style.borderColor = "#4C84FF";
    },
    removeIptColor(e) {
      e.target.parentNode.style.borderColor = "#616B8B";
    },
    showLoginout() {
      this.isshowLoginout = !this.isshowLoginout;
    },
    async go_out() {
      let { data } = await user_logout({
        token: localStorage.getItem("token"),
      });
      if (data.code == 200) {
        // localStorage.removeItem("user_password");
        localStorage.removeItem("member");
        // localStorage.removeItem("user_phone");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.$router.push({ name: "login" });
      }
      if (data.code == 3) {
        localStorage.removeItem("user_password");
        localStorage.removeItem("member");
        localStorage.removeItem("user_phone");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
<style lang="less">
//因为前人引入了一些不太方便的样式，此处进行覆盖
ol,
dl,
ul {
  margin-bottom: 0;
}
</style>
<style lang="less" scoped>
@import "@/style/mixin.less";
.extension_wrap {
  position: relative;
  background: #f0f2f5;
  margin-left: 40px;
  min-width: 1845px;
  min-height: 100vh;
  .nav_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    .nav_bar {
      height: 60px;
      .flex();
      .nav_bar_left {
        .flex();
        .logo {
          width: 202px;
          height: 28px;
          margin-right: 28px;
        }
        .nav {
          .flex();
          .nav_item {
            height: 60px;
            padding: 0 13px;
            margin-right: 10px;
            line-height: 60px;
            font-size: 16px;
            color: #616b8b;
            cursor: pointer;
            &:hover {
              color: #4c84ff;
            }
            &.active {
              color: #2c354b;
              border-bottom: 3px solid #4c84ff;
            }
          }
        }
      }
      .nav_bar_right {
        .flex();
        .search {
          width: 260px;
          height: 38px;
          padding: 12px;
          margin-right: 24px;
          border-radius: 4px;
          border: 1px solid #616b8b;
          .flex();
          input {
            width: 210px;
            background-color: transparent !important;
          }
          .search_img {
            width: 20px;
            height: 20px;
          }
        }
        .login_btn {
          width: 68px;
          height: 38px;
          background: #4c84ff;
          border-radius: 4px;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 38px;
        }
        .portrait {
          height: 36px;
          .flex();
          position: relative;
          .user_name {
            width: fit-content;
            flex: none;
            padding-left: 22px;
            height: 60px;
            font-size: 14px;
            color: #374567;
            line-height: 60px;
            background-repeat: no-repeat;
            background-position: 0px 21px;
            background-size: 18px 18px;
            background-image: url("~@/assets/img/团队@2x.png");
            cursor: pointer;
          }
          .down_arr_img {
            width: 16px;
            height: 16px;
          }
          .popup {
            position: absolute;
            left: 0;
            top: 38px;
            width: 140px;
            height: 72px;
            background: #ffffff;
            box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
              0px 16px 24px 2px rgba(0, 0, 0, 0.04),
              0px 6px 30px 5px rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            border: 1px solid #dcdcdc;
            .popup_item {
              height: 36px;
              font-size: 13px;
              color: #2c354b;
              line-height: 36px;
              cursor: pointer;
              .flex(flex-start);
              .icon {
                width: 16px;
                height: 16px;
                margin: 0 4px 0 16px;
                vertical-align: middle;
              }
              .extension_manage_icon {
                background: url("~@/assets/img/extension/extension_manage.png")
                  0 0 / cover;
              }
              .loginout_icon {
                background: url("~@/assets/img/extension/loginout_icon.png") 0 0 /
                  cover;
              }
              &:hover {
                background: #f7f9fd;
                color: #4c84ff;
                .extension_manage_icon {
                  background: url("~@/assets/img/extension/extension_manage_hover.png")
                    0 0 / cover;
                }
                .loginout_icon {
                  background: url("~@/assets/img/extension/loginout_icon_hover.png")
                    0 0 / cover;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
