<template>
	<div class="sidebar">
		<ul class="left_fixed">
			<li class="left_fixed_item" @click="editVisible = true">
				<img src="@/assets/img/extension/add_extension.png" style="width: 100%; height: 100%" alt="" />
			</li>
			<li class="left_fixed_item_bottom" @click="backTop">
				<img src="@/assets/img/extension/back_top.png" style="width: 100%; height: 100%" alt="" />
			</li>
		</ul>
		<!-- 编辑插件界面 -->
		<a-modal class="editModal" :width="600" v-model="editVisible" :footer="null">
			<template slot="title">
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="提交插件">
						<!-- 选择提交插件的方式 -->
						<div class="submit_extension">
							<div class="submit_extension_item">
								<img src="@/assets/img/extension/submit_market_extension.png" alt="" class="submit_extension_item_top" />
								<p class="submit_extension_item_title">申请同步市场插件</p>
								<p class="submit_extension_item_desc">您可直接提交在谷歌市场上架的插件，我们审核通过之后将自动上架并通知您。</p>
								<!-- <div class="submit_btn" @click="isShowMarketEx = true"> -->
								<div class="submit_btn" @click="showMessage">去申请</div>
							</div>
							<div class="submit_extension_item">
								<img src="@/assets/img/extension/submit_enterprise_extension.png" alt="" class="submit_extension_item_top" />
								<p class="submit_extension_item_title">提交企业内使用插件</p>
								<p class="submit_extension_item_desc">您可以上传插件包，仅用于企业内部使用，如插件已在插件中心存在，请勿重复提交。</p>
								<!-- <div
                  class="submit_btn"
                  @click="enterpriseExModalVisible = true"
                > -->
								<div class="submit_btn" @click="showMessage">去提交</div>
							</div>
						</div>
						<!-- 提交市场插件 -->
						<div class="market_extension" v-show="isShowMarketEx">
							<a-form-model :model="marketExForm" :label-col="labelCol" :wrapper-col="wrapperCol">
								<a-form-model-item :labelCol="{ span: 3 }" label="插件来源">
									<a-radio-group value="1">
										<a-radio value="1"> 谷歌应用商店 </a-radio>
										<!-- <a-radio value="2"> 火狐扩展中心 </a-radio> -->
									</a-radio-group>
								</a-form-model-item>
								<a-form-model-item label="输入Chorome应用商店上的插件URL">
									<a-input v-model="marketExForm.name" />
								</a-form-model-item>
							</a-form-model>
							<div class="footer_btns">
								<div class="back_btn pointer" @click="isShowMarketEx = false">返回</div>
								<div class="submit_btn pointer" @click="submitMarketEx">提交</div>
							</div>
						</div>
						<!-- 提交企业插件 -->
						<a-modal class="enterpriseExModal" :width="1300" title="添加企业自建插件" v-model="enterpriseExModalVisible" @ok="submitEnterpriseEx">
							<a-form-model ref="enterpriseExForm" :model="enterpriseExForm" :label-col="labelCol" :wrapper-col="wrapperCol">
								<p class="form_title">基础信息</p>
								<a-row type="flex" justify="space-between">
									<a-col :span="10">
										<a-form-model-item label="应用名称" :required="true">
											<a-input v-model="enterpriseExForm.name" placeholder="请输入应用名称" />
										</a-form-model-item>
										<a-form-model-item label="应用副标题" :required="true">
											<a-input v-model="enterpriseExForm.name" placeholder="请输入应用副标题" />
										</a-form-model-item>
										<a-form-model-item label="应用分类" prop="name">
											<a-tree-select
												v-model="categoryList"
												style="width: 100%"
												:tree-data="treeData"
												@change="chooseCategory"
												:multiple="true"
												search-placeholder="Please select" />
										</a-form-model-item>
									</a-col>
									<a-col :span="10">
										<a-form-model-item label="应用图标" prop="name">
											<a-upload
												name="file"
												list-type="picture-card"
												class="avatar-uploader"
												:show-upload-list="false"
												:action="upload_url"
												:data="uptoken"
												:before-upload="beforeUpload"
												@change="handleChange">
												<img v-if="imageUrl" class="upload_img" :src="imageUrl" alt="avatar" />
												<div v-else>
													<a-icon :type="loading ? 'loading' : 'plus'" />
													<div class="ant-upload-text">点击上传</div>
												</div>
											</a-upload>
											<p class="upload_desc">PNG格式，1M以内，240*240px，无圆角</p>
										</a-form-model-item>

										<a-form-model-item label="应用安装文件" prop="name">
											<a-upload
												name="file"
												class="avatar-uploader"
												:action="upload_url"
												:data="uptoken"
												:before-upload="beforeUploadEx"
												@change="handleChangeEx"
												><a-button> <a-icon type="upload" /> 上传文件 </a-button>
											</a-upload>
											<p class="upload_desc" style="top: 18px">支持拓展名：.crx</p>
										</a-form-model-item>
									</a-col>
								</a-row>
								<p class="form_title">应用商店设置</p>
								<a-row type="flex" justify="space-between">
									<a-col :span="10">
										<a-form-model-item label="可见范围" :required="true">
											<a-input v-model="enterpriseExForm.name" placeholder="设置可见此插件的公司名称" />
										</a-form-model-item>
										<a-form-model-item label="应用描述">
											<a-textarea
												v-model="enterpriseExForm.name"
												placeholder="请输入应用概述，更多内容建议使用图片描述，不超过3000字"
												:auto-size="{ minRows: 3 }" />
										</a-form-model-item>
										<a-form-model-item label="应用详情展示页" :required="true">
											<a-upload
												:action="upload_url"
												:data="uptoken"
												list-type="picture-card"
												:file-list="fileList"
												:before-upload="beforeUploadDetail"
												@preview="handlePreview"
												@change="handleDetailChange">
												<div v-if="fileList.length < 8">
													<a-icon type="plus" />
													<div class="ant-upload-text">Upload</div>
												</div>
											</a-upload>
											<a-modal :visible="previewVisible" :footer="null" @cancel="handlePreviewCancel">
												<img alt="example" style="width: 100%" :src="previewImage" />
											</a-modal>
											<p class="upload_desc">jpg、jpeg、png格式，不超过5M</p>
										</a-form-model-item>
									</a-col>
									<a-col :span="10">
										<a-form-model-item label="开发商名称" :required="true">
											<a-input v-model="enterpriseExForm.name" placeholder="请输入开发商名称" />
										</a-form-model-item>
										<a-form-model-item label="开发商联系方式" :required="true">
											<a-input v-model="enterpriseExForm.name" placeholder="请输入开发者联系方式，电话或邮箱" />
										</a-form-model-item>
										<a-form-model-item label="隐私协议">
											<a-input v-model="enterpriseExForm.name" placeholder="请输入应用隐私协议网址" />
										</a-form-model-item>
										<a-form-model-item label="帮助文档">
											<a-input v-model="enterpriseExForm.name" placeholder="请输入应用帮助文档网址" />
										</a-form-model-item>
										<a-form-model-item label="使用协议">
											<a-input v-model="enterpriseExForm.name" placeholder="请输入应用使用协议网址" />
										</a-form-model-item>
									</a-col>
								</a-row>
							</a-form-model>
						</a-modal>
					</a-tab-pane>
					<a-tab-pane key="2" tab="我的提交" disabled>
						<div class="my_extension">
							<div class="filter_header">
								<div class="filter_header_item">
									<span style="margin-right: 8px">进度</span
									><a-select placeholder="请选择进度" style="width: 120px">
										<!-- <a-select-option value="jack"> Jack </a-select-option>
                    <a-select-option value="lucy"> Lucy </a-select-option> -->
									</a-select>
								</div>
								<div class="filter_header_item">
									<span style="margin-right: 8px">类型</span
									><a-select placeholder="请选择类型" style="width: 120px">
										<!-- <a-select-option value="jack"> Jack </a-select-option>
                    <a-select-option value="lucy"> Lucy </a-select-option> -->
									</a-select>
								</div>
								<div class="filter_btn">提交</div>
							</div>
							<div class="extension_table">
								<a-table :columns="columns" :data-source="myExData"> </a-table>
							</div>
						</div>
					</a-tab-pane>
				</a-tabs>
			</template>
		</a-modal>
	</div>
</template>

<script>
import { getBase64 } from "@/utils/utils.js";
export default {
	data() {
		return {
			editVisible: false,
			isShowMarketEx: false,
			enterpriseExModalVisible: false,
			marketExForm: {},
			enterpriseExForm: {},
			myExFilterForm: {},
			categoryList: [],
			labelCol: { span: 8 },
			wrapperCol: { span: 14 },
			loading: false,
			imageUrl: "",
			uptoken: {
				version: "",
				mask: "",
				platform: "",
				token: "",
				type: 1, //1 插件图标 2 插件详情图片 3 插件.crx或者.zip文件
			},
			upload_url: "",
			previewVisible: false,
			previewImage: "",
			fileList: [],
			treeData: [
				{
					title: "Node1",
					value: "0-0",
					key: "0-0",
					children: [
						{
							title: "Child Node1",
							value: "0-0-0",
							key: "0-0-0",
						},
					],
				},
				{
					title: "Node2",
					value: "0-1",
					key: "0-1",
					children: [
						{
							title: "Child Node3",
							value: "0-1-0",
							key: "0-1-0",
							disabled: true,
						},
						{
							title: "Child Node4",
							value: "0-1-1",
							key: "0-1-1",
						},
						{
							title: "Child Node5",
							value: "0-1-2",
							key: "0-1-2",
						},
					],
				},
			],
			myExData: [
				{
					key: "1",
					name: "John Brown",
					age: 32,
					address: "New York No. 1 Lake Park",
				},
				{
					key: "2",
					name: "Jim Green",
					age: 42,
					address: "London No. 1 Lake Park",
				},
				{
					key: "3",
					name: "Joe Black",
					age: 32,
					address: "Sidney No. 1 Lake Park",
				},
			],
			columns: [
				{ title: "插件名称", dataIndex: "name", key: "name" },
				{
					title: "类型",
					dataIndex: "age",
					key: "age",
				},
				{
					title: "进度",
					dataIndex: "address",
					key: "address",
				},
				{
					title: "提交时间",
					key: "tags",
					dataIndex: "tags",
					scopedSlots: { customRender: "tags" },
				},
			],
		};
	},
	created() {
		this.uptoken.version = localStorage.version;
		this.uptoken.mask = localStorage.mask;
		this.uptoken.platform = localStorage.platform;
		this.uptoken.token = localStorage.token;
		this.upload_url = "https://api.yangtao.com/client_v1/upload/plugin";
	},
	methods: {
		showMessage() {
			this.$message.warning("该功能暂未开放");
		},
		backTop() {
			document.documentElement.scrollTo({
				top: 0,
				left: 0,
			});
		},
		//选择分类
		chooseCategory(value, label, extra) {},
		//上传插件图标
		handleChange(info) {
			if (info.file.status === "uploading") {
				this.loading = true;
				return;
			}
			if (info.file.status === "done") {
				if (info.file.response.code == 200) {
					this.front_id = info.file.response.data.id;
				}

				getBase64(info.file.originFileObj, imageUrl => {
					this.imageUrl = imageUrl;
					this.loading = false;
				});
			}
		},
		beforeUpload(file) {
			this.uptoken.type = 1;
			const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
			if (!isJpgOrPng) {
				this.$message.error("You can only upload JPG file!");
			}
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				this.$message.error("Image must smaller than 2MB!");
			}
			return isJpgOrPng && isLt2M;
		},
		//上传插件文件
		beforeUploadEx() {
			this.uptoken.type = 3;
		},
		handleChangeEx(a) {},

		//上传插件详情展示图
		handlePreviewCancel() {
			this.previewVisible = false;
		},
		handlePreview(file) {
			if (!file.url && !file.preview) {
				getBase64(file.originFileObj, imgUrl => {
					this.previewImage = file.url || imgUrl;
					this.previewVisible = true;
				});
			}
		},
		handleDetailChange({ fileList }) {
			this.fileList = fileList;
		},
		beforeUploadDetail(a) {
			this.uptoken.type = 2;
		},
		// 提交市场插件
		submitMarketEx() {},
		//提交企业插件
		submitEnterpriseEx() {
			this.enterpriseExModalVisible = false;
		},
	},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.editModal {
	::v-deep .ant-modal-header {
		border-bottom: 0 solid !important;
	}
}
.sidebar {
	.left_fixed {
		position: fixed;
		right: 20px;
		bottom: 180px;
		.left_fixed_item {
			width: 92px;
			height: 92px;
			cursor: pointer;
		}
		.left_fixed_item_bottom {
			width: 92px;
			height: 92px;
			position: absolute;
			left: 0;
			top: 60px;
			cursor: pointer;
		}
	}
}
.submit_extension {
	width: 476px;
	margin: 0 auto;
	padding-top: 20px;
	.flex();
	.submit_extension_item {
		width: 218px;
		height: 275px;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #e4e4e4;
		overflow: hidden;
		.submit_extension_item_top {
			width: 100%;
			height: 112px;
		}
		.submit_extension_item_title {
			padding: 0 16px;
			margin: 16px auto 9px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #2c354b;
			line-height: 22px;
		}
		.submit_extension_item_desc {
			padding: 0 16px;
			margin-bottom: 16px;
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #878fa7;
			line-height: 18px;
		}
		.submit_btn {
			width: 60px;
			height: 26px;
			margin: 0 auto;
			background: #4c84ff;
			box-shadow: 0px 2px 5px 0px rgba(76, 132, 255, 0.31);
			border-radius: 4px;
			line-height: 26px;
			text-align: center;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			cursor: pointer;
		}
	}
}
.my_extension {
	width: 526px;
	margin: 0 auto;
	.filter_header {
		.flex();
		.filter_btn {
			width: 60px;
			height: 32px;
			background: #4c84ff;
			border-radius: 4px;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			color: #ffffff;
			line-height: 32px;
			text-align: center;
		}
	}
}
.market_extension {
	padding-top: 20px;
	.footer_btns {
		.flex(flex-end);
	}
	.back_btn {
		width: 60px;
		height: 28px;
		margin-right: 12px;
		border-radius: 4px;
		border: 1px solid #e4e4e4;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #2c354b;
		line-height: 28px;
		text-align: center;
	}
	.submit_btn {
		width: 60px;
		height: 28px;
		background: #4c84ff;
		border-radius: 4px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 28px;
		text-align: center;
	}
}
.upload_desc {
	position: absolute;
	left: 0;
	top: 0px;
}
.form_title {
	padding-left: 12px;
	margin-bottom: 20px;
	border-left: 3px solid #4c84ff;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #2c354b;
}
</style>
